import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageShopOrder from 'Pages/shop-order';

const ShopOrder = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/:page`} component={ PageShopOrder } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default ShopOrder;