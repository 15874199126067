import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './DropdownMenu.less';

class DropdownMenu extends PureComponent {
	render() {
		const { active, width, className } = this.props;
		return (
			<Fragment>
				<div style={{ width }} className={ classnames('uk-card uk-card-default uk-card-body', styles.dropdownMenu, active && styles.active, className) }>
					<ul className="uk-nav uk-nav-center">
						{ this.props.children }
					</ul>
				</div>
			</Fragment>
		);
	}
}

DropdownMenu.propTypes = {
	active: PropTypes.bool.isRequired,
	width: PropTypes.string,
	children: PropTypes.node.isRequired,
	onToggle: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default DropdownMenu;