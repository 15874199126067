exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2NTRE {\n  cursor: pointer;\n}\n._2NTRE._2hzVb {\n  cursor: not-allowed;\n  opacity: 0.2;\n}\n._2NTRE._2hzVb._2T_fn {\n  opacity: 0;\n  cursor: default;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n._3txxn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: #fff;\n  color: #88733c;\n  width: 24px;\n  height: 24px;\n  line-height: 24px;\n  border: 2px solid #88733c;\n  border-radius: 24px;\n  text-align: center;\n  font-size: 14px;\n  font-weight: bold;\n}\n", ""]);

// Exports
exports.locals = {
	"button": "_2NTRE",
	"disabled": "_2hzVb",
	"hide": "_2T_fn",
	"badge": "_3txxn"
};