import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import loc from 'Components/languages';
import * as cons from 'Redux/constants';
import { findGiftCouponByInput } from 'Redux/actions';
import { compareJson } from 'Helpers/helpers';
import styles from './InsertCouponCodeListItem.less';

class InsertCouponCodeListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.onClickAddHandler = this.onClickAddHandler.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const criterion = this.props.criterion;
		const prevCriterion = prevProps.criterion;
		if (criterion !== prevCriterion) {
			this.setState({
				value: '',
			});
		}

		const giftCouponInfo = this.props.giftCouponInfo;
		const prevGiftCouponInfo = prevProps.giftCouponInfo;
		if (!compareJson(giftCouponInfo, prevGiftCouponInfo)) {
			switch (giftCouponInfo.type) {
			case cons.FIND_GIFT_COUPON_BY_INPUT.SUCCESS: {
				const couponResponse = giftCouponInfo.data || {};
				if (this.state.value && couponResponse.is_valid_coupon === false) {
					alert(loc.MSGCouponInvalid);
				}
				break;
			}
			}
		}
	}

	onClickAddHandler(e) {
		e.preventDefault();
		const { criterion } = this.props;
		const { value } = this.state;
		let couponCode = null;
		switch (criterion.type) {
		case 'single':
			couponCode = criterion.code;
			break;
		case 'pattern':
		case 'range':
			couponCode = value;
			break;
		}
		this.props.findGiftCouponByInput({
			payload: {
				value: couponCode,
			},
		});
	}

	onChangeHandler(e) {
		this.setState({
			value: e.target.value,
		});
	}

	render() {
		const { criterion } = this.props;
		const { value } = this.state;
		let body;
		switch (criterion.type) {
		case 'single':
			body = (
				<div className={ styles.wrapper } onClick={ this.onClickAddHandler }>
					<div className={ styles.body }>
						{ criterion.code }
					</div>
					<div className={ styles.icon }>
						<FontAwesomeIcon icon={ faPlusSquare } />
					</div>
				</div>
			);
			break;
		case 'pattern':
		case 'range':
			body = (
				<form onSubmit={ this.onClickAddHandler }>
					<div className={ styles.body }>
						<input
							className="uk-input"
							type="text"
							value={ value }
							onChange={ this.onChangeHandler }
						/>
					</div>
					<div className={ styles.icon } onClick={ this.onClickAddHandler }>
						<FontAwesomeIcon icon={ faPlusSquare } />
					</div>
					<div className={ styles.helperText }>
						{
							criterion.type === 'pattern' ? (
								`${loc.pattern}${criterion.pattern}`
							) : (
								`${loc.rangeFrom}${criterion.from} ${loc.rangeTo} ${criterion.to}`
							)
						}
					</div>
				</form>
			);
			break;
		}
		return (
			<div className={ classnames(styles.container, criterion.type === 'single' && styles.single) }>
				{ body }
			</div>
		);
	}
}

InsertCouponCodeListItem.propTypes = {
	criterion: PropTypes.object.isRequired,
};

export default connect(
	(state) => ({
		giftCouponInfo: state.giftCouponInfo,
	}),
	(dispatch) => ({
		findGiftCouponByInput: para => dispatch(findGiftCouponByInput(para)),
	})
)(InsertCouponCodeListItem);