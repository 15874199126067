exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1_dZw {\n  text-transform: capitalize;\n  background-color: #F6F4F0;\n  padding: 10px;\n  font-weight: bold;\n  color: #A89A72;\n}\n.V_k3N {\n  display: inline-block;\n  width: 50px;\n  text-align: center;\n}\n._3OGT- {\n  display: inline-block;\n  width: calc(100% - 50px);\n}\n._2C2U2::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n._2C2U2::-moz-placeholder {\n  text-transform: capitalize;\n}\n._2C2U2:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._2C2U2::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._2C2U2::placeholder {\n  text-transform: capitalize;\n}\n._1mury {\n  padding-right: 5px;\n}\n._1Rify {\n  padding-left: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"label": "_1_dZw",
	"unit": "V_k3N",
	"input": "_3OGT-",
	"inputQty": "_2C2U2",
	"buttonColumnLeft": "_1mury",
	"buttonColumnRight": "_1Rify"
};