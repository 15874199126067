import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { extractDateTimeFromDate } from 'Helpers/helpers';
import { setItemToCurrentShop, unsetItemFromCurrentShop } from 'Redux/actions';
import { connect } from 'react-redux';
import loc from 'Components/languages';
import { convertFloatToPrice } from 'Helpers/helpers';
import styles from './ItemListItem.less';
import 'rc-tooltip/assets/bootstrap_white.css';

class ItemListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			isCurrentShop: false,
		};
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onToggleCurrentShopHandler = this.onToggleCurrentShopHandler.bind(this);
	}

	componentDidMount() {
		const item = this.props.item;
		if (item) {
			this.setState({
				isCurrentShop: item.is_favorite,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const item = this.props.item;
		const prevItem = prevProps.item;
		if (item && item !== prevItem) {
			this.setState({
				isCurrentShop: item.is_favorite,
			});
		}
	}

	onClickHandler() {
		const { item, onClick } = this.props;
		onClick(item);
	}

	onToggleCurrentShopHandler() {
		const { item, setItemToCurrentShop, unsetItemFromCurrentShop } = this.props;
		const { isCurrentShop } = this.state;
		const param = {
			payload: { item_code: item.item_code },
		};

		if (isCurrentShop) {
			unsetItemFromCurrentShop(param);
		} else {
			setItemToCurrentShop(param);
		}

		this.setState({
			isCurrentShop: !isCurrentShop,
		});
	}

	render() {
		const { item, appLanguage, showCurrentShop, isSelected, expand } = this.props;
		const { isCurrentShop } = this.state;
		return (
			<li className={ classnames(styles.container, isSelected && styles.isSelected, expand && styles.expand) }>
				<div className={ classnames(styles.details, !showCurrentShop && styles.detailsFull) } onClick={ this.onClickHandler }>
					<div className={ styles.title }>
						{ appLanguage.language === 'en' ? item.item_name_en : item.item_name_tc }
					</div>
					<div className={ styles.desc }>
						<div className={ styles.row }>
							<div className={ styles.column }>
								{ loc.itemCode }: { item.item_code }
							</div>
							<div className={ styles.column }>
								{ loc.brandName }: { appLanguage.language === 'en' ? item.brand.name_en : item.brand.name_tc }
							</div>
							<div className={ styles.column }>
								{ loc.category }: { appLanguage.language === 'en' ? item.category.name_en : item.category.name_tc }
							</div>
							<div className={ styles.column }>
								{ loc.totalQty }: { item.stock_check_total_qty } ({ item.total_commit_qty })
							</div>
							<div className={ styles.column }>
								{ loc.unitPrice }: { convertFloatToPrice(item.unit_price) }
							</div>
						</div>
						{
							showCurrentShop && (
								<div className={ classnames(styles.row, styles.rowAddedBy) }>
									{ loc.addedBy }: { item.whouse_item_meta.created_by.id } { item.whouse_item_meta.created_by.name } ({ loc.at } { extractDateTimeFromDate(item.whouse_item_meta.created_at) })
								</div>
							)
						}
					</div>
				</div>
				{
					showCurrentShop && (
						<div className={ classnames(styles.star, isCurrentShop && styles.isSelected) } onClick={ this.onToggleCurrentShopHandler }>
							<FontAwesomeIcon icon={ isCurrentShop ? fasStar : farStar } />
						</div>
					)
				}
			</li>
		);
	}
}

ItemListItem.propTypes = {
	item: PropTypes.object.isRequired,
	isSelected: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	showCurrentShop: PropTypes.bool,
	expand: PropTypes.bool,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
	}),
	(dispatch) => ({
		setItemToCurrentShop: para => dispatch(setItemToCurrentShop(para)),
		unsetItemFromCurrentShop: para => dispatch(unsetItemFromCurrentShop(para)),
	})
)(ItemListItem);
