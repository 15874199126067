import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import loc from 'Components/languages';
import Tab from 'Components/tab';
import Pagination from 'Components/pagination';
import { getMember } from 'Redux/actions';
import MemberListItem from './MemberListItem.jsx';
import styles from './Modal.less';

class ModalMember extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			keyword: '',
			currentTab: 'allShop',
		};
		this.tabs = [
			'recentOrderMembers',
			'newMembers',
			'favoriteMembers',
			'currentShop',
			'allShop',
		];
		this.modalStyle = {
			content: {
				left: 'calc(50% - 401px)',
				right: 'calc(50% - 401px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen && isOpen !== prevIsOpen) {
			this.fetchData();
		}
	}

	fetchData() {
		const { type } = this.props;
		const { page, keyword, currentTab } = this.state;
		let param = {
			payload: {
				page,
				size: 20,
			},
		};
		switch (currentTab) {
		case 'recentOrderMembers':
			param.payload.filter = 'recent';
			param.payload.type = type;
			break;
		case 'newMembers':
			param.payload.filter = 'new';
			break;
		case 'favoriteMembers':
			param.payload.filter = 'favorite';
			break;
		case 'currentShop':
			param.payload.filter = 'current';
			break;
		case 'allShop':
			param.payload.filter = 'all';
			break;
		}
		if (keyword) {
			param.payload.keyword = keyword;
		}
		this.props.getMember(param);
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
		}, this.fetchData);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	render() {
		const { appLanguage, isOpen, onToggle, onSelect, memberInfo } = this.props;
		const { currentTab, keyword } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.memberSearch }
				style={ this.modalStyle }
			>
				<div className={ styles.desc }>
					{ loc.memberPopupDesc }
				</div>
				<div className={ styles.searchContainer }>
					<form onSubmit={ this.onSubmitHandler }>
						<div className={ styles.search }>
							<input
								type="text"
								value={ keyword }
								placeholder={ loc.memberSearchHelpText }
								className={ styles.input }
								onChange={ this.onChangeKeywordHandler }
							/>
							<div className={ styles.icon }>
								<FontAwesomeIcon icon={ faSearch } />
							</div>
						</div>
						<div className={ styles.btnSearch }>
							<button
								type="submit"
								disabled={ memberInfo.isFetching }
								className="uk-button uk-button-small uk-button-secondary"
							>
								{ loc.search }
							</button>
						</div>
					</form>
				</div>
				<div className={ styles.tab }>
					<Tab
						tabs={ this.tabs }
						current={ currentTab }
						className={ classnames(appLanguage.language === 'en' && styles.tabMember) }
						onChange={ this.onChangeTabHandler }
					/>
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							memberInfo.isFetching ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								memberInfo.data.data && memberInfo.data.data.map((member, index) => (
									<MemberListItem
										key={ `modal-member-info-${index}` }
										member={ member }
										onSelect={ onSelect }
									/>
								))
							)
						}
					</ul>
				</div>
				<Pagination
					data={ memberInfo.data }
					onPageChange={ this.onChangePageHandler }
				/>
			</Modal>
		);
	}
}

ModalMember.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
		memberInfo: state.memberInfo,
	}),
	(dispatch) => ({
		getMember: para => dispatch(getMember(para)),
	})
)(ModalMember);