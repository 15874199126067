exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".HK3JH {\n  padding: 15px 20px;\n  background-color: #fff;\n  font-size: 18px;\n  border-bottom: 1px solid #E1E1E1;\n}\n.HK3JH._2bEOJ {\n  color: #BB0000;\n}\n.HK3JH._394bb {\n  color: #49AA0D;\n}\n.HK3JH ._2lGJM {\n  display: inline-block;\n  vertical-align: middle;\n  width: calc(100% - 30px);\n}\n.HK3JH ._10gXm {\n  display: inline-block;\n  vertical-align: middle;\n  width: 30px;\n  font-size: 20px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "HK3JH",
	"missing": "_2bEOJ",
	"fulfilled": "_394bb",
	"body": "_2lGJM",
	"icon": "_10gXm"
};