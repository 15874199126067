import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import loc from 'Components/languages';
import styles from './SectionSpecialOptions.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

class Select extends PureComponent {
	constructor() {
		super();
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}

	onChangeHandler(selectedOption) {
		const { id, onChange } = this.props;
		onChange(id, selectedOption ? selectedOption.value : null);
	}

	render() {
		return (
			<ReactSelect
				{...this.props}
				onChange={ this.onChangeHandler }
			/>
		);
	}
}

class SectionSpecialOptions extends PureComponent {
	constructor() {
		super();
		this.state = {
			isExpanded: true,
		};
		this.getLanguageSlug = this.getLanguageSlug.bind(this);
		this.getOptions = this.getOptions.bind(this);
		this.getValue = this.getValue.bind(this);
		this.onToggleHandler = this.onToggleHandler.bind(this);
		this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
		this.onChangeSelectHandler = this.onChangeSelectHandler.bind(this);
		this.onChangeRadioHandler = this.onChangeRadioHandler.bind(this);
		this.renderField = this.renderField.bind(this);
	}

	getLanguageSlug() {
		const { appLanguage } = this.props;
		switch (appLanguage.language) {
		case 'en':
			return 'en';
		case 'zh-Hant':
			return 'tc';
		case 'zh-Hans':
			return 'sc';
		}
	}

	getOptions(options) {
		const slug = this.getLanguageSlug();
		return options.map(option => ({
			value: option.value,
			label: option[`label_${slug}`],
		}));
	}

	getValue(field) {
		const { salesOrder } = this.props;
		const options = this.getOptions(field.options);
		const value = salesOrder.get('special_options').get(field.id);
		return value ? options.find(option => value === option.value) : null;
	}

	onToggleHandler() {
		const { isExpanded } = this.state;
		this.setState({
			isExpanded: !isExpanded,
		});
	}

	onChangeInputHandler(e) {
		const { onChange } = this.props;
		const { id, value } = e.target;
		onChange(id, value);
	}

	onChangeSelectHandler(id, selectedOption) {
		const { onChange } = this.props;
		onChange(id, selectedOption);
	}

	onChangeRadioHandler(e) {
		const { onChange } = this.props;
		const { name, value } = e.target;
		onChange(name, value);
	}

	renderField(field, index) {
		const { section, isFetching, salesOrder } = this.props;
		const slug = this.getLanguageSlug();
		const value = salesOrder.get('special_options').get(field.id);
		let input = null;
		switch (field.type) {
		case 'text':
			input = (
				<input
					id={ field.id }
					type="text"
					className="uk-input uk-width-1-1"
					disabled={ isFetching }
					value={ value || '' }
					onChange={ this.onChangeInputHandler }
				/>
			);
			break;
		case 'textarea':
			input = (
				<textarea
					id={ field.id }
					rows="4"
					className="uk-textarea"
					disabled={ isFetching }
					value={ value || '' }
					onChange={ this.onChangeInputHandler }
				/>
			);
			break;
		case 'select':
			input = (
				<Select
					id={ field.id }
					isLoading={ isFetching }
					isDisabled={ isFetching }
					isClearable
					options={ this.getOptions(field.options) }
					isMulti={ false }
					value={ this.getValue(field) }
					placeholder={ loc.pleaseSelect }
					onChange={ this.onChangeSelectHandler }
				/>
			);
			break;
		case 'radio':
			input = (
				field.options.map((option, j) => (
					<label key={ `section-special-options-${section.id}-field-${index}-${j}` } className={ styles.radioLabel }>
						<input
							className="uk-radio"
							type="radio"
							name={ field.id }
							value={ option.value }
							checked={ value === option.value }
							onChange={ this.onChangeRadioHandler }
						/> { option[`label_${slug}`] }
					</label>
				))
			);
			break;
		}
		return (
			<div className={ styles.field } key={ `section-special-options-${section.id}-field-${index}` }>
				<div className={ classnames('uk-text-primary', styles.label) }>
					{ field[`label_${slug}`] }
				</div>
				<div className={ styles.value }>
					{ input }
				</div>
			</div>
		);
	}

	render() {
		const { section } = this.props;
		const { isExpanded } = this.state;
		const slug = this.getLanguageSlug();
		return (
			<Fragment>
				<div className={ styles.title } onClick={ this.onToggleHandler }>
					<div className={ styles.label }>
						{ section[`label_${slug}`] }
					</div>
					<div className={ styles.arrow }>
						<FontAwesomeIcon icon={ isExpanded ? faChevronUp : faChevronDown } />
					</div>
				</div>
				{
					isExpanded && (
						<div className={ styles.content }>
							{ section.fields.map(this.renderField) }
						</div>
					)
				}
			</Fragment>
		);
	}
}

SectionSpecialOptions.propTypes = {
	section: PropTypes.object.isRequired,
	isFetching: PropTypes.bool.isRequired,
	appLanguage: PropTypes.object.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SectionSpecialOptions;