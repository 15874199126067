import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { getSpecialOptions } from 'Redux/actions';
import loc from 'Components/languages';
import Button from 'Components/button';
import Modal from 'Components/modal';
import LoadingOverlay from 'Components/loading-overlay';
import SectionSpecialOptions from './SectionSpecialOptions.jsx';
import styles from './ModalSpecialOptions.less';

class ModalSpecialOptions extends PureComponent {
	constructor() {
		super();
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				maxHeight: '500px',
				padding: 0,
			},
		};
		this.isFetching = this.isFetching.bind(this);
		this.renderSection = this.renderSection.bind(this);
	}

	componentDidMount() {
		this.props.getSpecialOptions();
	}

	isFetching() {
		const { specialOptionsInfo } = this.props;
		return specialOptionsInfo.isFetching;
	}

	renderSection(section, index) {
		const { appLanguage, salesOrder, onChange } = this.props;
		const isFetching = this.isFetching();
		return (
			<SectionSpecialOptions
				key={ `modal-special-options-section-${index}` }
				section={ section }
				isFetching={ isFetching }
				appLanguage={ appLanguage }
				salesOrder={ salesOrder }
				onChange={ onChange }
			/>
		);
	}

	render() {
		const { specialOptionsInfo, isOpen, onToggle } = this.props;
		const isFetching = this.isFetching();
		return (
			<Modal
				title={ loc.additionalOptions }
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isFetching }>
					<div className={ styles.container }>
						{ specialOptionsInfo && specialOptionsInfo.data && specialOptionsInfo.data.map(this.renderSection) }
					</div>
					<div className={ styles.button }>
						<Button
							text={ loc.apply }
							theme="primary"
							onClick={ onToggle }
						/>
					</div>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalSpecialOptions.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		appLanguage: state.appLanguage,
		specialOptionsInfo: state.specialOptionsInfo,
	}),
	(dispatch) => ({
		getSpecialOptions: para => dispatch(getSpecialOptions(para)),
	})
)(ModalSpecialOptions);