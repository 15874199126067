import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputText from 'Components/input-text';

class InputField extends PureComponent {
	render() {
		const { label, required, noInput, footnote } = this.props;
		return (
			<div>
				{
					label && (
						<div className="uk-text-bold uk-margin-remove">
							{ label } { required && '*' }
						</div>
					)
				}
				<div className="uk-margin-left uk-flex uk-flex-column">
					{
						!noInput && (
							<InputText
								{...this.props}
							/>
						)
					}
					{
						footnote && (
							<div className="uk-text-muted uk-margin-small-top">
								{ footnote }
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

InputField.propTypes = {
	label: PropTypes.string,
	required: PropTypes.bool,
	noInput: PropTypes.bool,
	footnote: PropTypes.string,
};

export default InputField;