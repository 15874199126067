import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import loc from 'Components/languages';
import styles from './LotNoListItem.less';

class LotNoListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { lotNo, onSelect } = this.props;
		onSelect(lotNo);
	}

	render() {
		const { lotNo } = this.props;
		return (
			<li className={ styles.container } onClick={ this.onClickHandler }>
				{ lotNo.lot_no }

				<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
					<div className="uk-width-1-2">
						{ loc.qty }: { lotNo.quantity }
					</div>
					<div className="uk-width-1-2">
						{ loc.commitQty }: { lotNo.commit_quantity }
					</div>
				</div>
			</li>
		);
	}
}

LotNoListItem.propTypes = {
	lotNo: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default LotNoListItem;