import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getRemark } from 'Redux/actions';
import loc from 'Components/languages';
import Tab from 'Components/tab';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import RemarkListItem from './RemarkListItem.jsx';
import styles from './ModalRemark.less';

class ModalRemark extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			keyword: '',
			currentTab: 'edit',
			remark: '',
		};
		this.tabs = [
			'edit',
			'template',
		];
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangeRemarkHandler = this.onChangeRemarkHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onClickUpdateHandler = this.onClickUpdateHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const { remark } = this.props;
		this.setState({
			remark: remark || '',
		});
	}

	componentDidUpdate(prevProps) {
		const remark = this.props.remark;
		const prevRemark = prevProps.remark;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (remark !== prevRemark) {
			this.setState({
				currentTab: 'edit',
				remark: remark || '',
			});
		} else if (isOpen && isOpen !== prevIsOpen) {
			this.setState({
				page: 1,
				keyword: '',
				remark: remark || '',
			}, this.fetchData);
		}
	}

	fetchData() {
		const { docType } = this.props;
		const { page, keyword } = this.state;
		if (!docType) {
			return;
		}
		let param = {
			payload: {
				transaction_type: docType,
				page,
			}
		};
		if (keyword !== '') {
			param.payload.keyword = keyword;
		}
		this.props.getRemark(param);
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
		});
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onChangeRemarkHandler(e) {
		let value = e.target.value;
		if (value.length > 1000) {
			value = value.substring(0, 1000);
		}
		this.setState({
			remark: value,
		});
	}

	onSelectHandler(remark) {
		this.setState({
			currentTab: 'edit',
			remark: remark.content,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onClickUpdateHandler(e) {
		e.preventDefault();
		const { onChange, onToggle } = this.props;
		const { remark } = this.state;
		onChange(remark);
		onToggle();
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	render() {
		const { isOpen, isLoading, onToggle, remarkInfo } = this.props;
		const { currentTab, page, keyword, remark } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.remark }
				style={ this.modalStyle }
			>
				<div className={ styles.button } onClick={ this.onClickUpdateHandler }>
					{ loc.update }
				</div>
				<div className={ styles.desc }>
					{ loc.remarkPopupDesc }
				</div>
				<div className={ styles.searchContainer }>
					<form onSubmit={ this.onSubmitHandler }>
						<div className={ styles.search }>
							<input
								type="text"
								value={ keyword }
								placeholder={ loc.typeAndSearch }
								className={ styles.input }
								onChange={ this.onChangeKeywordHandler }
							/>
							<div className={ styles.icon }>
								<FontAwesomeIcon icon={ faSearch } />
							</div>
						</div>
						<div className={ styles.btnSearch }>
							<button
								type="submit"
								disabled={ remarkInfo.isFetching }
								className="uk-button uk-button-small uk-button-secondary"
							>
								{ loc.search }
							</button>
						</div>
					</form>
				</div>
				<div className={ styles.tab }>
					<Tab
						tabs={ this.tabs }
						current={ currentTab }
						onChange={ this.onChangeTabHandler }
					/>
				</div>

				{
					currentTab === 'edit' && (
						<Fragment>
							<div className={ styles.edit }>
								<textarea
									cols="30"
									rows="14"
									className={ classnames('uk-textarea', styles.textarea) }
									style={{ resize: 'none' }}
									placeholder={ loc.remark }
									disabled={ isLoading }
									value={ remark || '' }
									onChange={ this.onChangeRemarkHandler }
								/>
							</div>
						</Fragment>
					)
				}

				{
					currentTab === 'template' && (
						<Fragment>
							<div className={ styles.list }>
								{
									remarkInfo.isFetching ? (
										<Spinner />
									) : (
										<ul className="uk-list uk-list-divider">
											{
												remarkInfo.data && remarkInfo.data.data && remarkInfo.data.data.map((remark, index) => (
													<RemarkListItem
														key={ `modal-remark-list-item-${index}` }
														remark={ remark }
														onSelect={ this.onSelectHandler }
													/>
												))
											}
										</ul>
									)
								}
							</div>

							<Pagination
								data={ remarkInfo.data }
								onPageChange={ this.onChangePageHandler }
								forcePage={ page - 1 }
							/>
						</Fragment>
					)
				}
			</Modal>
		);
	}
}

ModalRemark.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	docType: PropTypes.string.isRequired,
	remark: PropTypes.string,
};

export default connect(
	(state) => ({
		remarkInfo: state.remarkInfo,
	}),
	(dispatch) => ({
		getRemark: option => dispatch(getRemark(option)),
	})
)(ModalRemark);