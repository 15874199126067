import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { setFavoriteMember, unsetFavoriteMember } from 'Redux/actions';
import loc from 'Components/languages';
import commonStyles from './SalesmanListItem.less';
import styles from './MemberListItem.less';

class MemberListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			isFavorite: false,
		};
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onToggleFavoriteHandler = this.onToggleFavoriteHandler.bind(this);
	}

	componentDidMount() {
		const member = this.props.member;
		if (member) {
			this.setState({
				isFavorite: member.is_favorite,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const member = this.props.member;
		const prevMember = prevProps.member;
		if (member && (!prevMember || member.is_favorite !== prevMember.is_favorite)) {
			this.setState({
				isFavorite: member.is_favorite,
			});
		}
	}

	onClickHandler() {
		const { member, onSelect } = this.props;
		onSelect(member);
	}

	onToggleFavoriteHandler() {
		const { member } = this.props;
		const { isFavorite } = this.state;
		const param = {
			payload: { member: member.member_code },
		};

		if (isFavorite) {
			this.props.unsetFavoriteMember(param);
		} else {
			this.props.setFavoriteMember(param);
		}

		this.setState({
			isFavorite: !isFavorite,
		});
	}

	render() {
		const { member } = this.props;
		const { isFavorite } = this.state;
		return (
			<li className={ commonStyles.container }>
				<div className={ commonStyles.details } onClick={ this.onClickHandler }>
					<div className={ styles.title }>
						{ member.member_code }
					</div>
					<div className={ styles.name }>
						<span className={ styles.label }>{ loc.memberName }: </span>
						{ member.member_name }
					</div>
					<div className={ styles.details }>
						<div className={ styles.phone }>
							<span className={ styles.label }>{ loc.phone }: </span>
							{ member.phone }
						</div>
						<div className={ styles.tier }>
							<span className={ styles.label }>{ loc.memberTier }: </span>
							{ member.member_group }
						</div>
					</div>
					{
						member.trx_no && (
							<div className={ styles.name }>
								<span className={ styles.label }>{ loc.orderNumber }: </span>
								{ member.trx_no }
							</div>
						)
					}
				</div>
				<div className={ classnames(commonStyles.star, isFavorite && commonStyles.isSelected) } onClick={ this.onToggleFavoriteHandler }>
					<FontAwesomeIcon icon={ isFavorite ? fasStar : farStar } />
				</div>
			</li>
		);
	}
}

MemberListItem.propTypes = {
	member: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		setFavoriteMemberInfo: state.setFavoriteMemberInfo,
		unsetFavoriteMemberInfo: state.unsetFavoriteMemberInfo,
	}),
	(dispatch) => ({
		setFavoriteMember: para => dispatch(setFavoriteMember(para)),
		unsetFavoriteMember: para => dispatch(unsetFavoriteMember(para)),
	})
)(MemberListItem);