import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './CriterionListItem.less';

class CriterionListItem extends PureComponent {
	render() {
		const { criterion, fulfilled } = this.props;
		return (
			<div className={ classnames(styles.container, fulfilled ? styles.fulfilled : styles.missing) }>
				<div className={ styles.body }>
					{ criterion }
				</div>
				<div className={ styles.icon }>
					<FontAwesomeIcon icon={ fulfilled ? faCheck : faTimes } />
				</div>
			</div>
		);
	}
}

CriterionListItem.propTypes = {
	criterion: PropTypes.string.isRequired,
	fulfilled: PropTypes.bool.isRequired,
};

export default CriterionListItem;