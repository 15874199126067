import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import { getServiceItems } from 'Redux/actions';
import * as cons from 'Redux/constants';
import { compareJson } from 'Helpers/helpers';
import loc from 'Components/languages';
import LoadingOverlay from 'Components/loading-overlay';
import ServiceItemRow from './ServiceItemRow.jsx';
import styles from './SectionSelectServiceItems.less';

class SectionSelectServiceItems extends PureComponent {
	constructor() {
		super();
		this.state = {
			selected: new Set(),
			unselected: new Set(),
		};
		this.resetState = this.resetState.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onClickUpdateHandler = this.onClickUpdateHandler.bind(this);
		this.onClickResetHandler = this.onClickResetHandler.bind(this);
		this.renderCategory = this.renderCategory.bind(this);
		this.renderSubCategory = this.renderSubCategory.bind(this);
		this.renderServiceItem = this.renderServiceItem.bind(this);
	}

	componentDidUpdate(prevProps) {
		const transactionItemCodeSet = this.props.transactionItemCodeSet;
		const prevTransactionItemCodeSet = prevProps.transactionItemCodeSet;
		const serviceItemsInfo = this.props.serviceItemsInfo;
		const prevServiceItemsInfo = prevProps.serviceItemsInfo;
		if (
			serviceItemsInfo.type === cons.GET_SERVICE_ITEMS.SUCCESS && ( // Ensure that service items are loaded
				(transactionItemCodeSet && transactionItemCodeSet !== prevTransactionItemCodeSet) ||
				!compareJson(serviceItemsInfo, prevServiceItemsInfo)
			)
		) {
			this.resetState();
		}
	}

	isLoading() {
		const { serviceItemsInfo, itemInfo, salesOrderInfo } = this.props;
		return serviceItemsInfo.isFetching || itemInfo.isFetching || salesOrderInfo.isFetching;
	}

	resetState() {
		const transactionItemCodeSet = this.props.transactionItemCodeSet;
		const serviceItemsInfo = this.props.serviceItemsInfo;
		let selected = new Set();
		for (let i = 0; i < serviceItemsInfo.data.service_items.length; i++) {
			for (let j = 0; j < serviceItemsInfo.data.service_items[i].sub_categories.length; j++) {
				for (let k = 0; k < serviceItemsInfo.data.service_items[i].sub_categories[j].service_items.length; k++) {
					const itemCode = serviceItemsInfo.data.service_items[i].sub_categories[j].service_items[k].code;
					if (transactionItemCodeSet.includes(itemCode)) {
						selected = selected.add(itemCode);
					}
				}
			}
		}
		this.setState({
			selected,
			unselected: new Set(),
		});
	}

	onSelectHandler(serviceItem) {
		const { transactionItemCodeSet } = this.props;
		let { selected, unselected } = this.state;
		if (selected.includes(serviceItem.code)) {
			selected = selected.delete(serviceItem.code);
			if (transactionItemCodeSet.includes(serviceItem.code)) {
				unselected = unselected.add(serviceItem.code);
			}
		} else {
			selected = selected.add(serviceItem.code);
			unselected = unselected.delete(serviceItem.code);
		}
		this.setState({
			selected,
			unselected,
		});
	}

	onClickUpdateHandler() {
		const { onChange, transactionItemCodeSet } = this.props;
		let { selected, unselected } = this.state;
		selected = selected.subtract(transactionItemCodeSet); // Remove selected item codes if it is already in the cart
		onChange(selected, unselected);
	}

	onClickResetHandler(e) {
		e.preventDefault();
		this.resetState();
	}

	renderCategory(category) {
		return (
			<div className={ styles.category } key ={ `modal-select-service-items-${category.code}` }>
				<h3 className={ classnames('uk-text-secondary', 'uk-text-bold', styles.categoryTitle) }>{ category.name }</h3>

				{ category.sub_categories.map(this.renderSubCategory) }
			</div>
		);
	}

	renderSubCategory(subCategory) {
		return (
			<div className={ styles.subCategory } key={ `modal-select-service-items-${subCategory.code}` }>
				<h6 className={ classnames('uk-text-bold', styles.subCategoryTitle) }>{ subCategory.name }</h6>

				{ subCategory.service_items.map(this.renderServiceItem) }
			</div>
		);
	}

	renderServiceItem(serviceItem) {
		const { selected } = this.state;
		const isSelected = selected.includes(serviceItem.code);
		return (
			<ServiceItemRow
				key={ `modal-select-service-items-${serviceItem.code}` }
				serviceItem={ serviceItem }
				isSelected={ isSelected }
				onSelect={ this.onSelectHandler }
			/>
		);
	}

	render() {
		const { serviceItemsInfo } = this.props;
		const isLoading = this.isLoading();
		return (
			<Fragment>
				<div className={ styles.btnUpdate } onClick={ this.onClickUpdateHandler }>
					{ loc.update }
				</div>
				<div className={ styles.btnReset } onClick={ this.onClickResetHandler }>
					{ loc.reset }
				</div>
				<LoadingOverlay className={ styles.container } active={ isLoading }>
					{
						serviceItemsInfo.data
						&& serviceItemsInfo.data.service_items
						&& serviceItemsInfo.data.service_items.map(
							this.renderCategory
						)
					}
				</LoadingOverlay>
			</Fragment>
		);
	}
}

SectionSelectServiceItems.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	transactionItemCodeSet: PropTypes.instanceOf(Set).isRequired,
};

export default connect(
	(state) => ({
		serviceItemsInfo: state.serviceItemsInfo,
		itemInfo: state.itemInfo,
		salesOrderInfo: state.salesOrderInfo,
	}),
	(dispatch) => ({
		getServiceItems: option => dispatch(getServiceItems(option)),
	})
)(SectionSelectServiceItems);