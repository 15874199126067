exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2qu4k {\n  padding: 10px 20px;\n  background-color: #EFF1F2;\n  color: #A0A0A0;\n  font-weight: bold;\n  cursor: pointer;\n}\n._2qu4k .r6514 {\n  display: inline-block;\n  width: calc(100% - 20px);\n}\n._2qu4k ._33eLu {\n  display: inline-block;\n  width: 20px;\n  text-align: right;\n}\n._1mBIL {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n._1mBIL ._19rjM {\n  padding: 5px 20px;\n}\n._1mBIL ._19rjM .r6514 {\n  display: inline-block;\n  width: 250px;\n  vertical-align: top;\n  line-height: 40px;\n}\n._1mBIL ._19rjM ._2thlf {\n  display: inline-block;\n  width: calc(100% - 250px);\n  vertical-align: top;\n}\n._1mBIL ._19rjM ._2thlf ._2tNo2 {\n  display: inline-block;\n  vertical-align: top;\n  line-height: 40px;\n  margin-right: 50px;\n}\n", ""]);

// Exports
exports.locals = {
	"title": "_2qu4k",
	"label": "r6514",
	"arrow": "_33eLu",
	"content": "_1mBIL",
	"field": "_19rjM",
	"value": "_2thlf",
	"radioLabel": "_2tNo2"
};