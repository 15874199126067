import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loc from 'Components/languages';
import Button from 'Components/button';
import Modal from 'Components/modal';
import styles from './ModalCartException.less';

class ModalCartException extends PureComponent {
	constructor() {
		super();
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '500px',
				padding: 0,
			},
		};
	}

	render() {
		const { isOpen, onToggle, data } = this.props;
		return (
			<Modal
				title={ loc.errorOccurred }
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<div className={ styles.container }>
					<ul className="uk-list uk-list-bullet">
						{
							data.data &&
							data.data.apiData &&
							data.data.apiData.cart_exceptions &&
							data.data.apiData.cart_exceptions.map((cartException, index) => (
								<li key={ `modal-cart-exception-${index}` } className="uk-text-capitalize">
									{/* <strong>{ cartException.errorCode }</strong>:  */}
									{ loc[cartException.errorCode] ? loc[cartException.errorCode] : cartException.message }
								</li>
							))
						}
					</ul>
				</div>
				<div className={ styles.button }>
					<Button
						text={ loc.confirm }
						theme="primary"
						onClick={ onToggle }
					/>
				</div>
			</Modal>
		);
	}
}

ModalCartException.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default connect(
	state => ({
		salesOrderInfo: state.salesOrderInfo,
	})
)(ModalCartException);