exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2yPMY._2OjRR {\n  border: 2px solid #b00;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  color: #b00;\n}\n._2yPMY._1qax8 {\n  border: 2px dashed #969696;\n  background-color: #F7F7F7;\n  color: #A0A0A0;\n}\n.V6kqW {\n  cursor: pointer;\n  padding-left: 20px;\n  padding-right: 20px;\n  margin-right: -20px;\n  margin-top: -5px;\n}\n._2C5Tq {\n  cursor: pointer;\n  font-weight: bold;\n  text-decoration: underline;\n}\n._1W8yp {\n  background-color: #b00;\n  color: #fff;\n  margin-left: 8px;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n", ""]);

// Exports
exports.locals = {
	"card": "_2yPMY",
	"invalid": "_2OjRR",
	"printDisable": "_1qax8",
	"button": "V6kqW",
	"link": "_2C5Tq",
	"badgeReturn": "_1W8yp"
};