exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2OTlh {\n  position: absolute;\n  top: 2px;\n  right: 60px;\n  padding: 10px 15px;\n  color: #A89A72;\n  cursor: pointer;\n}\n.epkgG {\n  position: absolute;\n  top: 2px;\n  right: 0;\n  padding: 10px 15px;\n  color: #A89A72;\n  cursor: pointer;\n}\n._3QNCZ {\n  height: calc(100% - 50px);\n  white-space: nowrap;\n  overflow: auto;\n}\n._3QNCZ ._1IdEy {\n  display: inline-block;\n  vertical-align: top;\n  width: 25%;\n  min-width: 320px;\n  height: 100%;\n  overflow: auto;\n  border-right: 2px solid #A0A0A0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  white-space: normal;\n}\n._3QNCZ ._1IdEy ._20_zA {\n  padding: 25px 20px 30px;\n  margin-bottom: 0;\n}\n._3QNCZ ._1IdEy ._2Q0K4 {\n  margin-bottom: 20px;\n}\n._3QNCZ ._1IdEy ._2Q0K4 ._3pr7Y {\n  background-color: #F8F8F8;\n  padding: 10px 20px 6px;\n  margin-bottom: 0;\n  line-height: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"btnUpdate": "_2OTlh",
	"btnReset": "epkgG",
	"container": "_3QNCZ",
	"category": "_1IdEy",
	"categoryTitle": "_20_zA",
	"subCategory": "_2Q0K4",
	"subCategoryTitle": "_3pr7Y"
};