import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import loc from 'Components/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import DropdownMenu from 'Components/dropdown-menu';
import ModalQuantity from './ModalQuantity.jsx';
import styles from './TransactionItemRow.less';

class CouponCodeRow extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
			showModalQuantity: false,
		};
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onToggleModalQuantityHandler = this.onToggleModalQuantityHandler.bind(this);
		this.onClickRemoveHandler = this.onClickRemoveHandler.bind(this);
		this.onChangeQuantityHandler = this.onChangeQuantityHandler.bind(this);
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		const { onShowOverlay, onHideOverlay } = this.props;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
		if (showDropdownMenu) {
			onHideOverlay();
		} else {
			onShowOverlay(this.onToggleDropdownMenuHandler);
		}
	}

	onToggleModalQuantityHandler() {
		const { showModalQuantity } = this.state;
		this.setState({
			showModalQuantity: !showModalQuantity,
		});
	}

	onClickRemoveHandler(e) {
		const { couponCode, onRemove } = this.props;
		e.preventDefault();
		onRemove(couponCode);
	}

	onChangeQuantityHandler(data) {
		const { couponCode, onChange } = this.props;
		onChange(couponCode, data);
	}

	render() {
		const { couponCode, quantity } = this.props;
		const { showDropdownMenu, showModalQuantity } = this.state;
		return (
			<Fragment>
				<div className="uk-card uk-card-default uk-card-body uk-card-small uk-box-shadow-medium uk-margin-small-left uk-margin-small-right">
					<div className="uk-flex">
						<div className="uk-width-3-5">
							{ couponCode }
						</div>
						<div className="uk-width-2-5 uk-flex uk-text-right uk-margin-medium-right">
							<div className="uk-width-1-3" />
							<div className={ classnames('uk-width-1-3', styles.link) } onClick={ this.onToggleModalQuantityHandler }>
								{ quantity }
							</div>
						</div>
					</div>
					<div className="uk-padding-small uk-padding-remove-vertical uk-position-top-right uk-position-small">
						<div className={ styles.button } onClick={ this.onToggleDropdownMenuHandler }>
							<FontAwesomeIcon icon={ faEllipsisV } />
						</div>
						<DropdownMenu width="200px" active={ showDropdownMenu } onToggle={ this.onToggleDropdownMenuHandler }>
							<li><a href="#" className="uk-text-danger" onClick={ this.onClickRemoveHandler }>{ loc.removeItem }</a></li>
						</DropdownMenu>
					</div>
				</div>

				<ModalQuantity
					isOpen={ showModalQuantity }
					quantity={ quantity }
					onToggle={ this.onToggleModalQuantityHandler }
					onChange={ this.onChangeQuantityHandler }
				/>
			</Fragment>
		);
	}
}

CouponCodeRow.propTypes = {
	couponCode: PropTypes.string.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
};

export default CouponCodeRow;