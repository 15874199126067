import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import styles from './PromotionListItem.less';

class PromotionListItem extends PureComponent {
	constructor() {
		super();
		this.isPassWithOrWithoutCouponCode = this.isPassWithOrWithoutCouponCode.bind(this);
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onViewDetailsHandler = this.onViewDetailsHandler.bind(this);
	}

	isPassWithOrWithoutCouponCode() {
		const { promotion } = this.props;
		if (promotion.pass) {
			return true;
		}
		if (promotion.conditions.fail.length === promotion.conditions.coupons.length) { // The missing conditions are all coupon-related
			const nonSingleCouponTypes = promotion.conditions.coupons.filter((coupon) => coupon.type !== 'single');
			return (nonSingleCouponTypes.length === 0); // All missing coupon conditions are "single"
		}
		return false;
	}

	onClickHandler(e) {
		const { type, promotion, onSelect, onApply } = this.props;
		const isPassWithOrWithoutCouponCode = this.isPassWithOrWithoutCouponCode();
		if (isPassWithOrWithoutCouponCode) {
			if (e.detail === 2) {
				onApply(e);
			} else {
				onSelect(type, promotion);
			}
		} else {
			this.onViewDetailsHandler();
		}
	}

	onViewDetailsHandler() {
		const { type, promotion, onViewDetails } = this.props;
		onViewDetails && onViewDetails(type, promotion.promotion_id);
	}

	renderCouponCode(coupons) {
		return coupons.map((criterion) => {
			switch (criterion.type) {
			case 'single':
				return criterion.code;
			case 'pattern':
				return `${loc.pattern}${criterion.pattern}`;
			case 'range':
				return `${loc.rangeFrom}${criterion.from} ${loc.rangeTo} ${criterion.to}`;
			}
		}).join('\n');
	}

	render() {
		const { type, promotion, isSelected } = this.props;
		const isPassWithOrWithoutCouponCode = this.isPassWithOrWithoutCouponCode();
		return (
			<li className={ classnames(styles.container, styles.selectable, isSelected && styles.isSelected) }>
				<div className={ styles.details } onClick={ this.onClickHandler }>
					<div className={ classnames(styles.title, isPassWithOrWithoutCouponCode && styles.pass) }>
						{ promotion.description || promotion.promotion_name }
					</div>
					<div className={ classnames(styles.desc, isPassWithOrWithoutCouponCode && styles.pass) }>
						{ this.renderCouponCode(promotion.conditions.coupons) }
					</div>
				</div>
				{
					// Render bardcode icon
					(
						!isPassWithOrWithoutCouponCode &&
						promotion.conditions.coupons.length > 0 // The missing conditions is coupon-related
					) ? (
							<div className={ styles.icon } onClick={ this.onViewDetailsHandler }>
								<FontAwesomeIcon icon={ faBarcode } />
							</div>
						) : (
							<div className={ styles.icon } />
						)
				}
				{
					type !== 'other' && (
						<div className={ classnames(styles.icon, isPassWithOrWithoutCouponCode && styles.pass) } onClick={ this.onViewDetailsHandler }>
							<FontAwesomeIcon icon={ faQuestionCircle } />
						</div>
					)
				}
			</li>
		);
	}
}

PromotionListItem.propTypes = {
	type: PropTypes.string.isRequired,
	promotion: PropTypes.object.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
	onApply: PropTypes.func.isRequired,
	onViewDetails: PropTypes.func,
};

export default PromotionListItem;