import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { getItem } from 'Redux/actions';
import Modal from 'Components/modal';
import loc from 'Components/languages';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import ModalAdvancedSearch from 'Components/modal/advanced-search';
import ItemListItem from './ItemListItem.jsx';
import styles from './ModalSelectItem.less';

class ModalSelectItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			size: 20,
			keyword: '',
			param: new Map({
				sort_direction: 'asc',
				sort_column: 'item_name_en',
			}),
			showModalAdvancedSearch: false,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onChangeParamHandler = this.onChangeParamHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onToggleModalAdvancedSearchHandler = this.onToggleModalAdvancedSearchHandler.bind(this);
	}

	componentDidMount() {
		const { keyword } = this.props;
		this.setState({
			keyword,
		}, this.fetchData);
	}

	componentDidUpdate(prevProps) {
		const keyword = this.props.keyword;
		const prevKeyword = prevProps.keyword;
		if (keyword !== prevKeyword) {
			this.setState({
				page: 1,
				keyword,
			}, this.fetchData);
		}
	}

	fetchData() {
		const { page, size, keyword } = this.state;
		const { type, isOpen, salesOrder } = this.props;
		if (!isOpen) {
			return;
		}
		let param = {
			payload: {
				...this.state.param.toJS(),
				page,
				size,
				keyword,
			},
		};
		switch (type) {
		case 'salesOrder':
			param.payload.is_active = true;
			param.payload.is_service_memo = false;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'serviceMemo':
			param.payload.is_active = true;
			param.payload.is_service_memo = true;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'shopOrder':
			break;
		case 'stockTransfer':
			param.payload.is_active = true;
			param.payload.is_stock_check = true;
			param.payload.is_current_shop = true;
			param.payload.is_stock_transfer = true;
			break;
		}
		this.props.getItem(param);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onChangeParamHandler(data) {
		const { param } = this.state;
		this.setState({
			param: param.merge(data),
			showModalAdvancedSearch: false,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	onToggleModalAdvancedSearchHandler() {
		const { showModalAdvancedSearch } = this.state;
		this.setState({
			showModalAdvancedSearch: !showModalAdvancedSearch,
		});
	}

	render() {
		const { isOpen, onToggle, itemInfo, onSelect } = this.props;
		const { keyword, param, showModalAdvancedSearch } = this.state;
		return (
			<Fragment>
				<Modal
					isOpen={ isOpen }
					onToggle={ onToggle }
					title={ loc.advancedSearch }
					style={ this.modalStyle }
				>
					<form onSubmit={ this.onSubmitHandler }>
						<div className={ styles.form }>
							<div className={ styles.wrapper }>
								<div className={ styles.search }>
									<input
										type="text"
										value={ keyword }
										placeholder={ loc.search }
										className={ styles.input }
										onChange={ this.onChangeKeywordHandler }
									/>
									<div className={ styles.icon }>
										<FontAwesomeIcon icon={ faSearch } />
									</div>
								</div>
								<div className={ styles.btnSearch }>
									<button
										type="submit"
										disabled={ itemInfo.isFetching }
										className="uk-button uk-button-small uk-button-secondary"
									>
										{ loc.search }
									</button>
								</div>
								<div className={ styles.btnAdvancedSearch } onClick={ this.onToggleModalAdvancedSearchHandler }>
									<FontAwesomeIcon icon={ faSlidersH } />
								</div>
							</div>
						</div>
						<div className={ styles.list }>
							<ul className="uk-list uk-list-divider">
								{
									itemInfo.isFetching ? (
										<div className="uk-margin-top uk-margin-bottom">
											<Spinner />
										</div>
									) : (
										itemInfo.data.data && itemInfo.data.data.map((item, index) => (
											<ItemListItem
												key={ `modal-advanced-search-item-${index}` }
												item={ item }
												onClick={ onSelect }
											/>
										))
									)
								}
							</ul>
						</div>
						<Pagination
							data={ itemInfo.data }
							onPageChange={ this.onChangePageHandler }
						/>
					</form>
				</Modal>

				<ModalAdvancedSearch
					isOpen={ showModalAdvancedSearch }
					onToggle={ this.onToggleModalAdvancedSearchHandler }
					param={ param }
					onChange={ this.onChangeParamHandler }
				/>
			</Fragment>
		);
	}
}

ModalSelectItem.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	keyword: PropTypes.string,
	type: PropTypes.string.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		itemInfo: state.itemInfo,
	}),
	(dispatch) => ({
		getItem: para => dispatch(getItem(para)),
	})
)(ModalSelectItem);