import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { setUserCurrentShop, unsetUserCurrentShop } from 'Redux/actions';
import styles from './SalesmanListItem.less';

class SalesmanListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			isCurrentShop: false,
		};
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onToggleCurrentShopHandler = this.onToggleCurrentShopHandler.bind(this);
	}

	componentDidMount() {
		const salesman = this.props.salesman;
		if (salesman) {
			this.setState({
				isCurrentShop: salesman.is_current_shop,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const salesman = this.props.salesman;
		const prevSalesman = prevProps.salesman;
		if (salesman && salesman !== prevSalesman) {
			this.setState({
				isCurrentShop: salesman.is_current_shop,
			});
		}
	}

	onClickHandler() {
		const { salesman, onSelect } = this.props;
		onSelect(salesman);
	}

	onToggleCurrentShopHandler() {
		const { salesman } = this.props;
		const { isCurrentShop } = this.state;
		const param = {
			payload: { username: salesman.user_id },
		};

		if (isCurrentShop) {
			this.props.unsetUserCurrentShop(param);
		} else {
			this.props.setUserCurrentShop(param);
		}

		this.setState({
			isCurrentShop: !isCurrentShop,
		});
	}

	render() {
		const { salesman } = this.props;
		const { isCurrentShop } = this.state;
		return (
			<li className={ styles.container }>
				<div className={ styles.details } onClick={ this.onClickHandler }>
					<div className={ styles.title }>{ salesman.user_id }</div>
					<div className={ styles.desc }>
						{ salesman.user_name }
					</div>
				</div>
				<div className={ classnames(styles.star, isCurrentShop && styles.isSelected) } onClick={ this.onToggleCurrentShopHandler }>
					<FontAwesomeIcon icon={ isCurrentShop ? fasStar : farStar } />
				</div>
			</li>
		);
	}
}

SalesmanListItem.propTypes = {
	salesman: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		setCurrentShopUserInfo: state.setCurrentShopUserInfo,
		unsetCurrentShopUserInfo: state.unsetCurrentShopUserInfo,
	}),
	(dispatch) => ({
		setUserCurrentShop: para => dispatch(setUserCurrentShop(para)),
		unsetUserCurrentShop: para => dispatch(unsetUserCurrentShop(para)),
	})
)(SalesmanListItem);