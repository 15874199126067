import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import loc from 'Components/languages';
import styles from './ProgressStep.less';

class ProgressStep extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		// const { id, onStepClick } = this.props;
		e.preventDefault();
		// onStepClick(id);
	}

	render() {
		const { id, step, current } = this.props;
		return (
			<button
				className={ classnames('uk-button uk-button-text uk-padding-remove', styles.button) }
				onClick={ this.onClickHandler }
			>
				<div className={ classnames('uk-text-center', 'uk-margin-remove', 'uk-badge', styles.badge, current && styles.active) }>
					{ step }
				</div>
				<br />
				{ loc[id] }
			</button>
		);
	}
}

ProgressStep.propTypes = {
	id: PropTypes.string.isRequired,
	step: PropTypes.number.isRequired,
	current: PropTypes.bool.isRequired,
	onStepClick: PropTypes.func.isRequired,
};

export default ProgressStep;