import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './RemarkListItem.less';

class RemarkListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { remark, onSelect } = this.props;
		onSelect(remark);
	}

	render() {
		const { remark } = this.props;
		return (
			<li className={ styles.container } onClick={ this.onClickHandler }>
				{ remark.content }
			</li>
		);
	}
}

RemarkListItem.propTypes = {
	remark: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default RemarkListItem;