import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import { receiveApi } from 'Helpers/helpers';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import { compareJson } from 'Helpers/helpers';
import { getFavoriteItems } from 'Redux/actions';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import ItemListItem from './ItemListItem.jsx';
import styles from './SectionFavoriteItems.less';

class SectionFavoriteItems extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			size: 20,
			is_favorite: true,
			param: new Map({
				sort_direction: 'asc',
				sort_column: 'item_name_en',
			}),
			selected: new Set(),
			unselected: new Set(),
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onClickUpdateHandler = this.onClickUpdateHandler.bind(this);
		this.onClickResetHandler = this.onClickResetHandler.bind(this);
		this.renderItemListItem = this.renderItemListItem.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const setItemToCurrentShopInfo = this.props.setItemToCurrentShopInfo;
		const prevSetItemToCurrentShopInfo = prevProps.setItemToCurrentShopInfo;
		const unsetItemFromCurrentShopInfo = this.props.unsetItemFromCurrentShopInfo;
		const prevUnsetItemFromCurrentShopInfo = prevProps.unsetItemFromCurrentShopInfo;
		const transactionItemCodeSet = this.props.transactionItemCodeSet;
		const prevTransactionItemCodeSet = prevProps.transactionItemCodeSet;
		const favoriteItemsInfo = this.props.favoriteItemsInfo;
		const prevFavoriteItemsInfo = prevProps.favoriteItemsInfo;

		if (
			receiveApi(setItemToCurrentShopInfo, prevSetItemToCurrentShopInfo, cons.SET_ITEM_TO_CURRENT_SHOP) ||
			receiveApi(unsetItemFromCurrentShopInfo, prevUnsetItemFromCurrentShopInfo, cons.UNSET_ITEM_FROM_CURRENT_SHOP)
		) {
			this.fetchData();
		}

		if (
			favoriteItemsInfo.type === cons.GET_FAVORITE_ITEMS.SUCCESS && ( // Ensure that favorite items are loaded
				(transactionItemCodeSet && transactionItemCodeSet !== prevTransactionItemCodeSet) ||
				!compareJson(favoriteItemsInfo, prevFavoriteItemsInfo)
			)
		) {
			this.resetState();
		}
	}

	fetchData() {
		const { page, size, is_favorite } = this.state;
		const { type, salesOrder } = this.props;
		let param = {
			payload: {
				...this.state.param.toJS(),
				page,
				size,
				is_favorite,
			},
		};
		switch (type) {
		case 'salesOrder':
			param.payload.is_active = true;
			param.payload.is_service_memo = false;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'serviceMemo':
			param.payload.is_active = true;
			param.payload.is_service_memo = true;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'shopOrder':
			break;
		case 'stockTransfer':
			param.payload.is_active = true;
			param.payload.is_stock_check = true;
			param.payload.is_current_shop = true;
			param.payload.is_stock_transfer = true;
			break;
		}
		this.props.getFavoriteItems(param);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	resetState() {
		const { transactionItemCodeSet, favoriteItemsInfo } = this.props;
		let selected = new Set();
		for (let i = 0; i < favoriteItemsInfo.data.data.length; i++) {
			const itemCode = favoriteItemsInfo.data.data[i].item_code;
			if (transactionItemCodeSet.includes(itemCode)) {
				selected = selected.add(itemCode);
			}
		}
		this.setState({
			selected,
			unselected: new Set(),
		});
	}

	onSelectHandler(item) {
		const { transactionItemCodeSet } = this.props;
		let { selected, unselected } = this.state;
		if (selected.includes(item.item_code)) {
			selected = selected.delete(item.item_code);
			if (transactionItemCodeSet.includes(item.item_code)) {
				unselected = unselected.add(item.item_code);
			}
		} else {
			selected = selected.add(item.item_code);
			unselected = unselected.delete(item.item_code);
		}
		this.setState({
			selected,
			unselected,
		});
	}

	onClickUpdateHandler() {
		const { onChange, transactionItemCodeSet } = this.props;
		let { selected, unselected } = this.state;
		selected = selected.subtract(transactionItemCodeSet); // Remove selected item codes if it is already in the cart
		onChange(selected, unselected);
	}

	onClickResetHandler(e) {
		e.preventDefault();
		this.resetState();
	}

	renderItemListItem(item, index) {
		const { selected } = this.state;
		const isSelected = selected.includes(item.item_code);
		return (
			<ItemListItem
				key={ `section-favorite-items-${index}` }
				item={ item }
				isSelected={ isSelected }
				onClick={ this.onSelectHandler }
				showCurrentShop={ true }
				expand
			/>
		);
	}

	render() {
		const { favoriteItemsInfo } = this.props;
		return (
			<Fragment>
				<div className={ styles.btnUpdate } onClick={ this.onClickUpdateHandler }>
					{ loc.update }
				</div>
				<div className={ styles.btnReset } onClick={ this.onClickResetHandler }>
					{ loc.reset }
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							(!favoriteItemsInfo || favoriteItemsInfo.isFetching) ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								favoriteItemsInfo.data.data && favoriteItemsInfo.data.data.map(this.renderItemListItem)
							)
						}
					</ul>
				</div>
				{
					favoriteItemsInfo && (
						<Pagination
							data={ favoriteItemsInfo.data }
							onPageChange={ this.onChangePageHandler }
						/>
					)
				}
			</Fragment>
		);
	}
}

SectionFavoriteItems.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
	transactionItemCodeSet: PropTypes.instanceOf(Set).isRequired,
};

export default connect(
	(state) => ({
		favoriteItemsInfo: state.favoriteItemsInfo,
		setItemToCurrentShopInfo: state.setItemToCurrentShopInfo,
		unsetItemFromCurrentShopInfo: state.unsetItemFromCurrentShopInfo,
	}),
	(dispatch) => ({
		getFavoriteItems: para => dispatch(getFavoriteItems(para)),
	})
)(SectionFavoriteItems);
