import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { List } from 'immutable';
import loc from 'Components/languages';
import TransactionItemRow from './TransactionItemRow.jsx';
import CouponCodeRow from './CouponCodeRow.jsx';
import styles from './Cart.less';

class Cart extends PureComponent {
	constructor() {
		super();
		this.getCouponCodes = this.getCouponCodes.bind(this);
	}

	getCouponCodes() {
		const { couponCodes } = this.props;
		let couponCodeMap = {};
		couponCodes.forEach((couponCode) => {
			if (!couponCodeMap[couponCode]) {
				couponCodeMap[couponCode] = {
					couponCode,
					quantity: 0,
				};
			}
			couponCodeMap[couponCode].quantity++;
		});
		return Object.values(couponCodeMap).sort((a, b) => a.couponCode.localeCompare(b.couponCode));
	}

	render() {
		const { transactionItems, couponCodes, exceptions, type, isReturn, onShowOverlay, onHideOverlay, onSelectLotNo, onChangeTransactionItem, onRemoveTransactionItem, onChangeCouponCode, onRemoveCouponCode } = this.props;
		const exceptionMap = {};
		exceptions.forEach((exception) => {
			if (exception.detail) {
				if (!exceptionMap[exception.detail]) {
					exceptionMap[exception.detail] = [];
				}
				exceptionMap[exception.detail].push(exception);
			}
		});
		return (
			<Fragment>
				{/* Items */}
				<div>
					<div className={ classnames('uk-flex uk-text-small uk-text-secondary uk-margin-small-left', styles.headerRow) }>
						<div className="uk-width-2-5">
							<span className={ styles.header }>{ loc.itemName }</span>
							<div className="uk-flex uk-child-width-1-2">
								<span className={ styles.header }>{ loc.itemCode }</span>
								<span className={ styles.header }>{ loc.lotNo }</span>
							</div>
						</div>
						<div className="uk-width-1-5">
							&nbsp;
							<div>
								<span className={ styles.header }>{ loc.brandName }</span>
							</div>
						</div>
						<div className="uk-width-2-5 uk-margin-medium-right">
							&nbsp;
							<div className="uk-flex uk-child-width-1-3 uk-text-right">
								<span className={ styles.header }>{ loc.unitPrice }</span>
								<span className={ styles.header }>{ loc.qty }</span>
								<span className={ styles.header }>{ loc.amount }</span>
							</div>
						</div>
					</div>
					<hr className="uk-margin-remove" />
					<div
						className="uk-padding-small uk-padding-remove-horizontal"
						data-uk-margin="margin: uk-margin-top"
					>
						{
							transactionItems.map((transactionItem, index) => (
								<TransactionItemRow
									key={ `cart-transaction-item-row-${index}` }
									index={ index }
									type={ type }
									isReturn={ isReturn }
									exceptions={
										exceptionMap[transactionItem.get('item_code')] ? exceptionMap[transactionItem.get('item_code')] : (
											exceptionMap[transactionItem.get('lot_no')] ? exceptionMap[transactionItem.get('lot_no')] : null
										)
									}
									transactionItem={ transactionItem }
									onShowOverlay={ onShowOverlay }
									onHideOverlay={ onHideOverlay }
									onSelectLotNo={ onSelectLotNo }
									onChange={ onChangeTransactionItem }
									onRemove={ onRemoveTransactionItem }
								/>
							))
						}
					</div>
				</div>

				{/* Coupon codes */}
				{
					couponCodes.size > 0 && (
						<div>
							<div className="uk-flex uk-text-small uk-text-secondary uk-margin-small-left uk-margin-large-right">
								<span className={ styles.header }>{ loc.coupon }</span>
							</div>
							<hr className="uk-margin-remove" />
							<div
								className="uk-padding-small uk-padding-remove-horizontal"
								data-uk-margin="margin: uk-margin-top"
							>
								{
									this.getCouponCodes().map((couponCodeObj, index) => (
										<CouponCodeRow
											key={ `cart-coupon-code-row-${index}` }
											quantity={ couponCodeObj.quantity }
											couponCode={ couponCodeObj.couponCode }
											onShowOverlay={ onShowOverlay }
											onHideOverlay={ onHideOverlay }
											onChange={ onChangeCouponCode }
											onRemove={ onRemoveCouponCode }
										/>
									))
								}
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

Cart.propTypes = {
	transactionItems: PropTypes.instanceOf(List).isRequired,
	couponCodes: PropTypes.instanceOf(List).isRequired,
	exceptions: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	isReturn: PropTypes.bool.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
	onSelectLotNo: PropTypes.func.isRequired,
	onChangeTransactionItem: PropTypes.func.isRequired,
	onRemoveTransactionItem: PropTypes.func.isRequired,
	onChangeCouponCode: PropTypes.func.isRequired,
	onRemoveCouponCode: PropTypes.func.isRequired,
};

export default Cart;