import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './PromotionListItem.less';

class MessageListItem extends PureComponent {
	render() {
		const { message } = this.props;
		return (
			<li className={ styles.container }>
				<div className={ styles.details }>
					<div className={ styles.title }>
						{ message }
					</div>
				</div>
			</li>
		);
	}
}

MessageListItem.propTypes = {
	message: PropTypes.string.isRequired,
};

export default MessageListItem;