import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './InputText.less';

class InputText extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		const { onFocus, onSubmit } = this.props;
		if (onFocus){
			onFocus(e);
		} else if (onSubmit) {
			onSubmit(e);
		}
	}

	render() {
		const { type = 'text', icon, myRef, autoFocus, className, extIcon } = this.props;
		let props = _.omit(this.props, ['icon', 'className', 'extIcon', 'myRef']);
		props.value = props.value || '';
		return (
			<div className="uk-flex uk-flex-1">
				<div className="uk-inline uk-width-expand">
					{
						icon && (
							<a className={ classnames('uk-form-icon', 'uk-form-icon-flip', styles.extIcon) } onClick={ this.onClickHandler }>
								<FontAwesomeIcon icon={ icon } className="fa-lg uk-text-primary" />
							</a>
						)
					}
					<input
						className={ classnames('uk-input', className) }
						type={ type }
						ref={ myRef }
						autoFocus={ autoFocus }
						{ ...props }
					/>
				</div>
				{
					extIcon && (
						<div className="uk-width-1-5">
							<div className={ classnames('uk-margin-medium-left', styles.extIcon) } onClick={ this.onClickHandler }>
								<FontAwesomeIcon icon={ extIcon } className="fa-2x uk-text-primary" />
							</div>
						</div>
					)
				}
			</div>
		);
	}
}

InputText.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	autoFocus: PropTypes.bool,
	icon: PropTypes.object,
	myRef: PropTypes.object,
	extIcon: PropTypes.object,
	placeholder: PropTypes.string,
	onFocus: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InputText;