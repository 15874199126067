exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._27Ztq {\n  padding-left: 20px;\n  padding-right: 20px;\n  cursor: pointer;\n}\n._27Ztq._2n2RS {\n  padding-left: 0;\n  padding-right: 0;\n}\n._27Ztq._2n2RS ._1AoFa {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._27Ztq ._1AoFa {\n  display: inline-block;\n  vertical-align: middle;\n  width: calc(100% - 80px);\n}\n._27Ztq ._1AoFa._16b6s {\n  width: 100%;\n}\n._27Ztq ._1AoFa .r-KVr {\n  color: #A89A72;\n  font-size: 16px;\n  font-weight: bold;\n}\n._27Ztq ._1AoFa ._1NKUw {\n  color: #A0A0A0;\n  font-size: 16px;\n}\n._27Ztq ._1AoFa ._1NKUw ._1lzQi {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  margin-top: 3px;\n}\n._27Ztq ._1AoFa ._1NKUw ._1lzQi.L2pca {\n  font-style: italic;\n  font-size: 12px;\n}\n._27Ztq ._1AoFa ._1NKUw ._1lzQi .vWp5f {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  text-transform: capitalize;\n}\n._27Ztq .sgJc7 {\n  display: inline-block;\n  vertical-align: middle;\n  width: 30px;\n  color: #A0A0A0;\n  text-align: center;\n  cursor: pointer;\n}\n._27Ztq .sgJc7._2kZ3z {\n  color: #A89A72;\n}\n._27Ztq._2kZ3z {\n  background-color: #A89A72;\n  padding-top: 10px;\n  margin-top: -10px;\n  padding-bottom: 10px;\n  margin-bottom: -10px;\n}\n._27Ztq._2kZ3z ._1AoFa {\n  background-color: #A89A72;\n}\n._27Ztq._2kZ3z ._1AoFa .r-KVr,\n._27Ztq._2kZ3z ._1AoFa ._1NKUw {\n  color: #fff;\n}\n._27Ztq._2kZ3z .sgJc7 {\n  background-color: #A89A72;\n  color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_27Ztq",
	"expand": "_2n2RS",
	"details": "_1AoFa",
	"detailsFull": "_16b6s",
	"title": "r-KVr",
	"desc": "_1NKUw",
	"row": "_1lzQi",
	"rowAddedBy": "L2pca",
	"column": "vWp5f",
	"star": "sgJc7",
	"isSelected": "_2kZ3z"
};