import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faBell, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Top from 'Components/top';
import DropdownMenu from 'Components/dropdown-menu';
import Overlay from 'Components/overlay';
import loc from 'Components/languages';
import ProgressStep from './ProgressStep.jsx';
import styles from './ProgressBar.less';

class ProgressBar extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
		};
		this.onClickPrevHandler = this.onClickPrevHandler.bind(this);
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onClickResetHandler = this.onClickResetHandler.bind(this);
		this.onClickHoldOrderHandler = this.onClickHoldOrderHandler.bind(this);
		this.onToggleModalPromotionsHandler = this.onToggleModalPromotionsHandler.bind(this);
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
	}

	onClickPrevHandler() {
		const { page, items, history } = this.props;
		const index = items.findIndex((item) => page == item);
		if (index > 0) {
			history.push(items[index - 1]);
		}
	}

	onClickResetHandler(e) {
		e.preventDefault();
		const { onReset } = this.props;
		onReset();
		this.onToggleDropdownMenuHandler();
	}

	onClickHoldOrderHandler(e) {
		e.preventDefault();
		const { onHold } = this.props;
		onHold();
		this.onToggleDropdownMenuHandler();
	}

	onToggleModalPromotionsHandler() {
		const { onToggleModalPromotions } = this.props;
		onToggleModalPromotions && onToggleModalPromotions('notification');
	}

	render() {
		const { name, page, items, onStepClick, onHold, salesOrder } = this.props;
		const { showDropdownMenu } = this.state;
		return (
			<Fragment>
				<Top
					name={ name }
					disableBack={ page === 'print' }
					onClickPrev={ items[0] == page ? undefined : this.onClickPrevHandler }
				>
					<div className="uk-width-expand uk-grid-item-match">
						<div className="uk-flex-center uk-child-width-auto" data-uk-grid>
							{
								items.map((step, index) => (
									<Fragment key={ `progress-bar-step-${index}` }>
										{
											index > 0 && (
												<div className="uk-text-primary">
													<FontAwesomeIcon icon={ faChevronRight } className="uk-margin-top" />
												</div>
											)
										}
										<div>
											<ProgressStep
												id={ step }
												step={ index + 1 }
												current={ page === step }
												onStepClick={ onStepClick }
											/>
										</div>
									</Fragment>
								))
							}
						</div>
					</div>
					<div className="uk-width-auto uk-flex-inline uk-flex-right">
						<div>
							<div
								className={ classnames(
									'uk-padding-small',
									styles.button,
									(page !== 'cart' || name === 'shopOrder') && styles.hide,
									'uk-position-relative',
									(page !== 'cart' || name === 'shopOrder' || !salesOrder.get('messages') || salesOrder.get('messages').length === 0) ? styles.disabled : 'uk-text-primary'
								) }
								onClick={ page === 'cart' ? this.onToggleModalPromotionsHandler : undefined }
							>
								{
									!(!salesOrder.get('messages') || salesOrder.get('messages').length === 0) && (
										<div className={ styles.badge }>
											{ salesOrder.get('messages').length }
										</div>
									)
								}
								<FontAwesomeIcon icon={ faBell } className="fa-2x" />
							</div>
						</div>
						<div>
							<div className={ classnames('uk-padding-small', styles.button) } onClick={ this.onToggleDropdownMenuHandler }>
								<FontAwesomeIcon icon={ faEllipsisV } className="fa-2x" />
							</div>
							<DropdownMenu
								width="200px"
								active={ showDropdownMenu }
								onToggle={ this.onToggleDropdownMenuHandler }
							>
								<li><a href="#" className="uk-text-danger" onClick={ this.onClickResetHandler }>{ loc.reset }</a></li>
								{
									onHold && (
										<li><a href="#" className="uk-text-danger" onClick={ this.onClickHoldOrderHandler }>{ loc.holdOrder }</a></li>
									)
								}
							</DropdownMenu>
						</div>
					</div>
				</Top>

				<Overlay
					isOpen={ showDropdownMenu }
					onToggle={ this.onToggleDropdownMenuHandler }
				/>
			</Fragment>
		);
	}
}

ProgressBar.propTypes = {
	name: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	history: PropTypes.object.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onHold: PropTypes.func,
	onToggleModalPromotions: PropTypes.func,
};

export default ProgressBar;