import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getItem } from 'Redux/actions';
import styles from './ServiceItemRow.less';

class ServiceItemRow extends PureComponent {
	constructor() {
		super();
		this.onSelectHandler = this.onSelectHandler.bind(this);
	}

	onSelectHandler() {
		const { onSelect, serviceItem } = this.props;
		onSelect(serviceItem);
	}

	render() {
		const { serviceItem, isSelected } = this.props;
		return (
			<div className={ classnames(styles.serviceItem, isSelected && styles.isSelected) } onClick={ this.onSelectHandler }>
				<div className={ styles.details }>
					<div className={ styles.title }>
						{ serviceItem.name }
					</div>
					<div className={ styles.desc }>
						{ serviceItem.desc }
					</div>
					<div className={ styles.code }>
						{ serviceItem.code }
					</div>
				</div>
			</div>
		);
	}
}

ServiceItemRow.propTypes = {
	serviceItem: PropTypes.object.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		itemInfo: state.itemInfo,
	}),
	(dispatch) => ({
		getItem: para => dispatch(getItem(para)),
	})
)(ServiceItemRow);