import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import styles from './Overlay.less';

class Overlay extends PureComponent {
	render() {
		const { isOpen, onToggle } = this.props;
		if (!isOpen) {
			return null;
		}
		return (
			<Fragment>
				<div
					className={ styles.overlay }
					onClick={ onToggle }
				/>
				<ScrollLock />
			</Fragment>
		);
	}
}

Overlay.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func,
};

export default Overlay;